.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: SourceSerif4;
    font-style: normal;
    font-weight: 400;
    src: local('SourceSerif4'), url(theme/fonts/SourceSerif4-Regular.ttf) format('truetype');
}

@font-face {
    font-family: Lexend;
    font-style: normal;
    font-weight: 400;
    /*
    load font from src/themes/fonts/Lexend-Regular.ttf
     */
    src: local('Lexend'), url(theme/fonts/Lexend-Regular.ttf) format('truetype');


    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 400;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-Italic-BETA.woff) format('woff');
    font-display: swap;

}


@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-Light-BETA.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Inter;
    font-style: italic;
    font-weight: 300;
    src: local('Inter'), url(https://fonts.cdnfonts.com/s/19795/Inter-LightItalic-BETA.woff) format('woff');
    font-display: swap;

}

@font-face {
    font-family: Lexend;
    font-style: normal;
    font-weight: 500;
    src: local('Lexend'), url(theme/fonts/Lexend-Medium.ttf) format('truetype');
    font-display: swap;

}


@font-face {
    font-family: Lexend;
    font-style: normal;
    font-weight: 600;
    src: local('Lexend'), url(theme/fonts/Lexend-SemiBold.ttf) format('truetype');
    font-display: swap;

}


@font-face {
    font-family: Lexend;
    font-style: normal;
    font-weight: 700;
    src: local('Lexend'), url(theme/fonts/Lexend-Bold.ttf) format('truetype');
    font-display: swap;

}


@font-face {
    font-family: Lexend;
    font-style: normal;
    font-weight: 800;
    src: local('Lexend'), url(theme/fonts/Lexend-ExtraBold.ttf) format('truetype');
    font-display: swap;

}


@font-face {
    font-family: Lexend;
    font-style: normal;
    font-weight: 900;
    src: local('Lexend'), url(theme/fonts/Lexend-Black.ttf) format('truetype');
    font-display: swap;

}


@import "~react-image-gallery/styles/css/image-gallery.css";

body {
    background-color: #fbf7ef;
}

.map-container {
    height: 95vh;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.mapboxgl-popup-content {
    width: 250px !important;
    border-radius: 12px !important;
}

.mapboxgl-popup-tip {

}

a {
    text-decoration: none;
    color: #485E75;
}
@media (min-width: 768px) {

    .mapboxgl-popup-content {
        width: 250px !important;
    }
}


@media (min-width: 768px) {
    .map-container {
        height: 525px;
        width: 100%;
        margin: 0 auto;
        position: relative;
    }
}

.image-gallery-svg {
    height: 50px !important;

}

.image-gallery-left-nav {
    padding: 5px 0px !important;
    width: 30px !important;
}

.image-gallery-right-nav {
    padding: 5px 0px !important;
}

.image-gallery-fullscreen-button {
    padding: 5px 10px !important;
}

.image-gallery-play-button {
    padding: 5px 10px !important;
}

.MuiContainer-root {
    margin: 0px 0px !important;
}


.HeadingLarge {
    font-family: 'SourceSerif4';
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 48px;
    color: #0A243E
}

.HeadingSmall {
    font-family: 'SourceSerif4';
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: #0A243E
}

@media (max-width: 900px) {

    .HeadingSmall {
        /*HeadingXsmall*/
        font-family: 'SourceSerif4';
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #0A243E
    }

}

@media (max-width: 900px) {

    .HeadingLarge {
        /*HeadingMedium*/
        font-family: 'SourceSerif4';
        font-weight: 400;
        font-size: 2rem;
        line-height: 41.8px;
        color: #0A243E
    }
}

@media (max-width: 400px) {

    .HeadingLarge {
        /*HeadingMedium*/
        font-family: 'SourceSerif4';
        font-weight: 400;
        font-size: 1.7rem;
        line-height: 41.8px;
        color: #0A243E
    }
}

.HeadingXLarge {
    font-family: 'SourceSerif4';
    font-weight: 400;
    font-size: 48px;
    line-height: 57.6px;
    color: #0A243E
}

@media (max-width: 600px) {
    .HeadingXLarge {
        font-family: 'SourceSerif4';
        font-weight: 400;
        font-size: 40px;
        line-height: 48px;
        color: #0A243E
    }
}

.HeadingMedium {
    font-family: 'SourceSerif4';
    font-weight: 400;
    font-size: 32px;
    line-height: 41.6px;
    color: #0A243E
}

@media (max-width: 600px) {

    .HeadingMedium {
        /* HeadingSmall */
        font-family: 'SourceSerif4';
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        color: #0A243E
    }
}

.HeadingXxlarge {
    font-family: 'SourceSerif4';
    font-weight: 400;
    font-size: 3.5rem;
    line-height: 67.2px;
    color: #0A243E
}

@media (max-width: 700px) {
    .HeadingXxlarge {
        font-family: 'SourceSerif4';
        font-weight: 400;
        font-size: 2.5rem;
        line-height: 48px;
        color: #0A243E
    }
}

@media (max-width: 400px) {
    .HeadingXxlarge {
        font-family: 'SourceSerif4';
        font-weight: 400;
        font-size: 2rem;
        line-height: 38px;
        color: #0A243E
    }
}


.HeadingXsmall {
    font-family: 'SourceSerif4';
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #0A243E
}

@media (max-width: 900px) {
    .HeadingXsmall {
        font-family: 'SourceSerif4';
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #0A243E
    }
}

.HeadingXXsmall {
    font-family: 'SourceSerif4';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0A243E
}

.TextMediumNormal {
    font-family: 'Lexend';
    font-weight: 400;
    font-size: 17px;
    line-height: 25.5px;
    color: #485E75
}

@media (max-width: 900px) {

    .TextMediumNormal {
        /*TextRegularNormal*/
        font-family: 'Lexend';
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #485E75
    }
}

@media (max-width: 400px) {

    .TextMediumNormal {
        /*TextRegularNormal*/
        font-family: 'Lexend';
        font-weight: 400;
        font-size: 0.85rem;
        line-height: 18px;
        color: #485E75
    }
}

.TextMediumMedium {
    font-family: 'Lexend';
    font-weight: 500;
    font-size: 17px;
    line-height: 25.5px;
    color: #485E75
}

@media (max-width: 400px) {

    .TextMediumMedium {
        /*TextRegularNormal*/
        font-family: 'Lexend';
        font-weight: 500;
        font-size: 1rem;
        line-height: 18px;
        color: #485E75
    }
}

.TextMediumSemibold {
    font-family: 'Lexend';
    font-weight: 600;
    font-size: 17px;
    line-height: 150%;
    color: #485E75
}

.TextLargeMedium {
    font-family: 'Lexend';
    font-weight: 500;
    font-size: 19px;
    line-height: 28.5px;
    color: #485E75
}

.TextXLargeMedium {
    font-family: 'Lexend';
    font-weight: 500;
    font-size: 21px;
    line-height: 36px;
    color: #485E75
}

.TextXLargeSemibold {
    font-family: 'Lexend';
    font-weight: 600;
    font-size: 21px;
    line-height: 36px;
    color: #485E75
}

@media (max-width: 900px) {
    .TextLargeMedium {
        font-family: 'Lexend';
        font-weight: 500;
        font-size: 17px;
        line-height: 25.5px;
        color: #485E75
    }
}

.TextRegularNormal {
    font-family: 'Lexend';
    font-weight: 400;
    font-size: 0.93rem;
    line-height: 22.5px;
    color: #485E75
}

@media (max-width: 400px) {
    .TextRegularNormal {
        font-family: 'Lexend';
        font-weight: 400;
        font-size: 0.80rem;
        line-height: 18px;
        color: #485E75
    }
}

.TextRegularMedium {
    font-family: 'Lexend';
    font-weight: 500;
    font-size: 0.93rem;
    line-height: 22.5px;
    color: #485E75
}

.TextSmallNormal {
    font-family: 'Lexend';
    font-weight: 400;
    font-size: 0.81rem;
    line-height: 19.5px;
    color: #485E75
}
.TextXSmallNormal {
    font-family: 'Lexend';
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 16px;
    color: #485E75
}

.TextXSmallSemibold {
    font-family: 'Lexend';
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 16px;
    color: #485E75
}

.TextSmallSemibold {
    font-family: 'Lexend';
    font-weight: 600;
    font-size: 0.81rem;
    line-height: 19.5px;
    color: #485E75
}

@media (max-width: 600px) {
    .TextSmallNormal {
        /*TextTinyNormal */
        font-family: 'Lexend';
        font-weight: 400;
        font-size: 0.75rem;
        color: #485E75
    }
}

.TextSmallMedium {
    font-family: 'Lexend';
    font-weight: 500;
    font-size: 0.81rem;
    line-height: 19.5px;
    color: #485E75
}

.TextSmallLight {
    font-family: 'Lexend';
    font-weight: 300;
    font-size: 0.81rem;
    line-height: 19.5px;
    color: #485E75
}

.TextTinyNormal {
    font-family: 'Lexend';
    font-weight: 400;
    font-size: 0.71rem;
    color: #485E75
}

.TextTinyLight {
    font-family: 'Lexend';
    font-weight: 300;
    font-size: 0.71rem;
    color: #485E75
}

.TextTinyMedium {
    font-family: 'Lexend';
    font-weight: 500;
    font-size: 0.81rem;
    color: #485E75
}

.TextTinySemibold {
    font-family: 'Lexend';
    font-weight: 600;
    font-size: 0.71rem;
    color: #485E75
}

.TextRegularLight {
    font-family: 'Lexend';
    font-weight: 300;
    font-size: 0.93rem;
    line-height: 22.5px;
    color: #485E75
}

.TextRegularSemibold {
    font-family: 'Lexend';
    font-weight: 600;
    font-size: 0.93rem;
    line-height: 22.5px;
    color: #485E75
}

MuiMenuItem-root {
    padding: 0px 16px !important;
}


@media (min-width: 960px) {
    .MuiGrid-grid-md-6.your-class {
        flex-grow: 0;
        max-width: 50%;
        flex-basis: 50%;
    }
}

@media (min-width: 600px) and (max-width: 959px) {
    .MuiGrid-grid-sm-12.your-class {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
    }
}

.MuiSelectLowPading div {
    padding: 10px !important;
}

.MuiFormControlLabel-label {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 0.83rem !important;
    line-height: 22.5px;
    color: #485E75
}


.slick-dots li.slick-active button:before {
    opacity: 1;
    width: 15px !important;
    height: 15px !important;
    display: inline-block;
    background: #FA8D62 !important;
    color: transparent !important;
    border-radius: 32px;
    position: relative;
    top: 0px;
    left: -7px;


}

.slick-dots li button:before {
    opacity: 1;
    width: 10px !important;
    height: 10px !important;
    display: inline-block;
    background: rgba(250, 141, 98, 0.43) !important;
    color: transparent !important;
    border-radius: 32px;

}

.slick-dots {
    bottom: -50px !important;
}

.slick-next, .slick-prev {
    width: 60px !important;
    height: 60px !important;
    Z-index: 1;
}

.slick-next{
    right: 0px !important;
}

.slick-prev{
    left: 0px !important;
}

.slick-list{
    border-radius: 24px !important;
}

.img-body img {
    display: flex;
    margin: 0 auto;
    height: 40vh;
    object-fit: cover;
    width: 97%;
    height: 100%;
    border-radius: 24px;

}

.img-body {
    width: 100%;
    height: 350px;
    aspect-ratio: 1/1;
    outline-color: red;
    display: inline-block;
}

@media screen and (max-width: 700px) {
    .img-body {
        width: 100%;
        height: 250px;
        aspect-ratio: 1/1;
        outline-color: red;
        display: inline-block;
    }

}



.MuiOutlinedInput-input{
    font-family: Lexend !important;
    border-radius: 16px !important;
    border: 0px solid #D0D5DD;
    font-weight: 400 !important;

    background-color: #fafcfe !important;
}

.MuiOutlinedInput-root {
    border-radius: 16px !important;
    font-family: Lexend !important;
    font-weight: 400 !important;
    font-size: 0.93rem !important;
   /* border: 1px solid #D0D5DD; */


}

legend {
    font-family: Lexend !important;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 20px;
    color: #485E75
}

.rdrDefinedRangesWrapper{
    display: block !important;
}

.image-gallery-slides {
    border-radius: 10px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    border-radius: 6px;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    border: 2px solid #FA8D62;
    outline: none;
    border-radius: 6px;

}

.custom-marker {
    border-radius: 24px;
    background-color: #1a3b42;
    border: 2px solid #1a3b42;
    cursor: pointer;
    color: white;
    padding: 3px 7px;
}

/* hide scrollbar but allow scrolling */
.scrollbarhidden {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}

.scrollbarhidden::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.dotted {
    content: '';
    background: repeating-linear-gradient(to right, currentColor, currentColor 1px, transparent 2px, transparent 4px);
    height: 1px;
    flex-grow: 1;
    display: inline-block;
    margin-top: 1em;

}

.MuiFileInput-TextField{
    background: white !important;
    border-radius: 24px !important;
}

@media screen and (max-width: 900px) {
    .flexgrid{
        flex-direction: column-reverse !important;
    }

}

.MuiDayCalendar-weekDayLabel {
    font-family: 'Lexend' !important;
    font-weight: 500;
    font-size: 0.83rem !important;
    line-height: 22.5px;
    color: #485E75
}

.MuiPickersCalendarHeader-label {
    font-family: 'Lexend' !important;
    font-weight: 500;
    font-size: 0.90rem !important;
    line-height: 22.5px;
    color: #485E75
}

.MuiDateCalendar-root {
    max-height: 300px !important;
}

.p-CardForm {
    display: flex !important;
}

.p-CardNumberInput {
    min-width: 255px !important;
}


textarea {
    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100% !important;
    font-size: 1rem !important;

    font-family: 'Lexend' !important;
}



.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows,.MuiDataGrid-columnHeaderTitle  {
    font-family: 'Lexend' !important;
    font-weight: 600 !important;
    font-size: 0.83rem !important;
    line-height: 22.5px;
    color: #485E75
}

.MuiDataGrid-cellContent{
    font-family: 'Lexend' !important;
    font-weight: 400;
    font-size: 0.83rem !important;
    line-height: 22.5px;
    color: #485E75
}

.MuiSelect-select:focus{
    border-radius: 16px !important;
}
